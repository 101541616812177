:root {
  --main-bg-color: white;
  --secondary-color: #282c34;
}

.App {
  background-color: var(--main-bg-color);
  color: var(--secondary-color);
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.App-display-pic {
  height: 20vmin;
  pointer-events: none;
  border-radius: 50%;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.App-body {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.App-header-container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.App-header-container a {
  color: var(--secondary-color);
  text-decoration: none;
  padding: 0 30px;
  font-size: 22px;
  transition: color 0.5s;
  height: 100%;
  border-bottom: 3px solid rgba(0,0,0,0);
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.App-toggle-container {
  margin-left: auto;
  padding-right: 15px;
  padding-top: 10px;
}

.App-contact-container ul {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: .625rem 0
}



.App-contact-container li {
  color: black;
  background-color: white;
  padding: 0;
  margin: 1rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  line-height: 3rem;
  border-radius: 50%;
  text-align: center;
  border: 1px solid var(--color-gray-border)
}

.App-name {
  margin: 0;
}

.App-icon-link {
  display: flex;
  width: 30px;
  height: 30px;
}

.App-title-description {
  display: flex;
  font-size: 0.8em;
}

.App-title-description p{
  margin: 1rem;
}

